<template>
  <div>
    <DownloadDialog
      :visible="showDownloadDialog"
      @tou-confirmed="onTouConfirmation"
      @close="showDownloadDialog = false"
      title="Download file"
    >
    </DownloadDialog>
    <DeleteDialog
      :visible="showDeleteDialog"
      @delete-confirmed="onDeleteConfirmed"
      @close="showDeleteDialog = false"
      title="Delete File?"
    >
    </DeleteDialog>
    <EventOverviewDialog
      v-if="showEventOverviewDialog"
      :visible="showEventOverviewDialog"
      :event-id="selectedEventId"
      :leg-id="legId"
      @close="
        showEventOverviewDialog = false;
        refresh()
      "
    >
    </EventOverviewDialog>
    <v-data-table
      class="osis-table elevation-2"
      :headers="headers"
      :items="files"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="filesItemsPerPage"
      @current-items="getFiltered"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      :expand-icon="$icons.mdiChevronDown"
      item-key="id"
      @item-expanded="loadOldVersion"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row v-if="!loadFilePermissions">
          <v-tooltip
            top
            v-if="
              filePermissions['download_file_' + item.id] &&
              filePermissions['download_file_' + item.id].permission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-blue mr-2"
                @click="downloadFile(item)"
              >
                {{$icons.mdiDownload}}
              </v-icon>
            </template>
            <span>Download</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          > {{$icons.mdiDownload}} </v-icon>
          <v-tooltip
            top
            v-if="
              filePermissions['upload_new_version_file_' + item.id] &&
              filePermissions['upload_new_version_file_' + item.id].permission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-blue mr-2"
                @click="updateFile(item)"
              >
                {{$icons.mdiUpload}}
              </v-icon>
            </template>
            <span>New Version</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          > {{$icons.mdiUpload}} </v-icon>
          <v-tooltip
            top
            v-if="
              filePermissions['edit_file_' + item.id] &&
              filePermissions['edit_file_' + item.id].permission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-orange mr-2"
                @click="editFile(item)"
              >
                {{$icons.mdiPencil}}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiPencil}}
          </v-icon>
          <v-tooltip
            top
            v-if="
              filePermissions['delete_file_' + item.id] &&
              filePermissions['delete_file_' + item.id].permission
            "
            content-class="osis-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="osis-red mr-2"
                @click="deleteFile(item)"
              >
                {{$icons.mdiDeleteOutline}}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-icon
            v-else
            small
            color="grey"
            class="mr-2"
          >
            {{$icons.mdiDeleteOutline}}
          </v-icon>
        </v-row>
      </template>
      <template v-slot:top>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-btn
                v-if="uploadNewFilePermission"
                class="elevation-1 plus-button"
                fab
                x-small
                @click="uploadFile()"
              >
                <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <FileUploadDialog
            v-if="showUploadDialog"
            :visible="showUploadDialog"
            @close="
              showUploadDialog = false;
              refresh();
            "
            :leg-id="legId"
            :event-id="eventId"
            :cruise-id="cruiseId"
            :experiment-id="experimentId"
            :simulation-id="simulationId"
            :event-network-id="eventNetworkId"
            :selected-file="selectedFile"
            :selected-method="selectedRestMethod"
          />
        </v-container>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div
          class="clickable-text linebreak"
          v-if="
              filePermissions['download_file_' + item.id] &&
              filePermissions['download_file_' + item.id].permission
            "
          @click="downloadFile(item)"
          style="font-weight: bold;"
        >
          {{ item.name }}
        </div>
        <div
          v-else
          class="linebreak"
        >
          {{ item.name }}
        </div>

      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ Math.round(item.size / 1024) }}
      </template>
      <template v-slot:[`item.related_to`]="{ item }">
        <div v-if="item.leg">
          <v-chip class="osis-chip leg-chip">
            Expedition {{ item.leg.name }}
          </v-chip>
        </div>
        <div v-if="item.event">
          <v-chip
            class="osis-chip event-chip"
            @click="showEventDialog(item.event.id)"
          >
            <div>
              Event {{ item.event.label }}
            </div>
          </v-chip>
        </div>
        <div v-if="item.cruise">
          <v-chip class="osis-chip cruise-chip">
            Cruise {{ item.cruise.label }}
          </v-chip>
        </div>
      </template>
      <template
        v-slot:expanded-item="{ item }"
        v-if=!loading
      >
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >

            <v-tooltip
              top
              content-class="osis-tooltip"
              v-if="file.name.length > nameStringLegth"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="no-wrap-cell"
                >
                  {{ shortenString(file.name) }}
                </div>
              </template>
              <span>{{file.name}}</span>
            </v-tooltip>
            <div
              v-else
              class="no-wrap-cell"
            >
              {{ shortenString(file.name) }}
            </div>

          </div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell ml-4"
          >{{ file.fileversion }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ Math.round(item.size / 1024) }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.flag }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >
            <v-tooltip
              top
              content-class="osis-tooltip"
              v-if="file.description&&file.description.length > nameStringLegth"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="no-wrap-cell"
                >
                  {{ shortenString(file.description) }}
                </div>
              </template>
              <span>{{file.description}}</span>
            </v-tooltip>
            <div
              v-else
              class="no-wrap-cell"
            >
              {{ shortenString(file.description) }}
            </div>

          </div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.date_uploaded }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
            class="no-wrap-cell"
          >{{ file.created_by }}</div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
          ></div>
        </td>
        <td>
          <div
            v-for="file in oldFileVersions"
            :key=file.id
          ></div>
        </td>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <div
          v-if="item.fileversion===1"
          class="ml-4"
        >
          {{item.fileversion}}
        </div>
        <div v-else>

          <v-btn
            elevation="1"
            fab
            small
            v-if="item.fileversion>1&&!isExpanded"
            @click="expand(true)"
          >
            <div style="font-size: 0.875rem;">
              {{item.fileversion}}
            </div>
          </v-btn>
          <v-btn
            elevation="1"
            fab
            small
            @click="expand(false)"
            v-if="isExpanded"
          ><v-icon> {{$icons.mdiChevronUp}} </v-icon></v-btn>
        </div>

      </template>
    </v-data-table>

  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'
import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'Files',
  props: ['legId', 'eventId', 'cruiseId', 'experimentId', 'eventNetworkId', 'simulationId'],
  mixins: [permission, apiRequests, helperFunctions],
  components: {
    FileUploadDialog: () => import('@/components/Files/FileUploadDialog.vue'),
    DownloadDialog: () => import('@/components/DownloadDialog.vue'),
    DeleteDialog: () => import('@/components/DeleteDialog.vue'),
    EventOverviewDialog: () => import('@/components/Events/EventOverviewDialog.vue'),
  },
  data() {
    return {
      files: [],
      oldFileVersions: [],
      loading: true,
      filePermissions: {},
      loadFilePermissions: false,
      uploadNewFilePermission: false,
      showUploadDialog: false,
      showDownloadDialog: false,
      selectedFile: null,
      selectedRestMethod: '',
      fileToDownload: {},
      filteredFiles: [],
      showDeleteDialog: false,
      showEventOverviewDialog: false,
      selectedEventId: null,
      nameStringLegth: 20,
      expanded: [],
      singleExpand: true,
    }
  },
  computed: {
    headers() {
      const baseHeaders = [
        { text: 'Filename', value: 'name' },
        { text: 'Version', value: 'data-table-expand' },
        { text: 'Size [kB]', value: 'size' },
        { text: 'Q-flag', value: 'flag' },
        { text: 'Description', value: 'description' },
        { text: 'Uploaded', value: 'date_uploaded' },
        { text: 'Creator', value: 'created_by' },
        { text: 'Actions', value: 'actions', sortable: false }
      ];

      if (!(this.eventId || this.cruiseId || this.experimentId || this.eventNetworkId || this.simulationId)) {
        baseHeaders.splice(7, 0, { text: 'Related to', value: 'related_to', sortable: false });
      }

      return baseHeaders;
    },
    filesItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("filesItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("filesItemsPerPage", value);
      },
    },
  },
  watch: {
    'filteredFiles': function (val, oldVal) {
      this.getFilePermissions()
    },
  },
  async mounted() {
    await this.getFiles()
    await this.getObjectPermissions()
  },
  methods: {
    async getPermissions() {
      await this.getObjectPermissions()
      await this.getFilePermissions()
    },
    async getObjectPermissions() {
      let route = ""
      let isMainObject = false
      if (this.legId) {
        isMainObject = true
      } else if (this.experimentId) {
        isMainObject = true
      } else if (this.simulationId) {
        isMainObject = true
      } else if (this.cruiseId) {
        route = '/v1/cruises/' + this.cruiseId + '/files'
      } else if (this.eventNetworkId) {
        route = '/v1/event_networks/' + this.eventNetworkId + '/files'
      }

      if (!isMainObject) {
        let routePermissionsToAsk = {
          'uploadNewFilePermission': {
            route: route,
            action: 'POST'
          }
        }
        let permissions = await this.getRoutePermission(routePermissionsToAsk)
        this.uploadNewFilePermission = permissions.uploadNewFilePermission.permission
      } else {
        if (this.legId) {
          let permissionsToAsk = {
            'patchPermission': { leg_id: this.legId, action: 'patch' }
          }
          let permissions = await this.getExpeditionPermissionFromApi(permissionsToAsk)
          this.uploadNewFilePermission = permissions.patchPermission.permission
        } else if (this.experimentId) {
          let permissionsToAsk = {
            'patchPermission': { experiment_id: this.experimentId, action: 'patch' }
          }
          let permissions = await this.getExperimentPermissionFromApi(permissionsToAsk)
          this.uploadNewFilePermission = permissions.patchPermission.permission
        } else if (this.simulationId) {
          let permissionsToAsk = {
            'patchPermission': { simulation_id: this.simulationId, action: 'patch' }
          }
          let permissions = await this.getSimulationPermissionFromApi(permissionsToAsk)
          this.uploadNewFilePermission = permissions.patchPermission.permission
        }
      }

    },
    async getFilePermissions() {
      this.loadFilePermissions = true
      let filePermissionsToAsk = {}
      if (this.filteredFiles.length > 0) {
        let file_ids = this.filteredFiles.map(({ id }) => id);
        for (const id of file_ids) {
          filePermissionsToAsk['download_file_' + id] = { file_id: id, action: 'download' }
          filePermissionsToAsk['upload_new_version_file_' + id] = { file_id: id, action: 'upload_new_version' }
          filePermissionsToAsk['edit_file_' + id] = { file_id: id, action: 'edit' }
          filePermissionsToAsk['delete_file_' + id] = { file_id: id, action: 'delete' }
        }
        this.filePermissions = await this.getFilePermissionFromApi(filePermissionsToAsk)
      }
      this.loadFilePermissions = false
    },
    async getFiles() {
      this.loading = true
      let requestParams = {}
      if (this.eventId) {
        requestParams = {
          method: "GET",
          url: 'expeditions/' + this.legId + '/events/' + this.eventId + '/files'
        }
      } else if (this.cruiseId) {
        requestParams = {
          method: "GET",
          url: 'cruises/' + this.cruiseId + '/files'
        }
      } else if (this.experimentId) {
        requestParams = {
          method: "GET",
          url: 'experiments/' + this.experimentId + '/files'
        }
      } else if (this.eventNetworkId) {
        requestParams = {
          method: "GET",
          url: 'event_networks/' + this.eventNetworkId + '/files'
        }
      } else if (this.simulationId) {
        requestParams = {
          method: "GET",
          url: 'simulations/' + this.simulationId + '/files'
        }
      } else {
        requestParams = {
          method: "GET",
          url: 'expeditions/' + this.legId + '/files',
          queryParams: {
            include_cruise: true,
            include_events: true
          }
        }
      }

      try {
        const res = await this.osisApiRequest(requestParams)
        this.files = await res.json()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    async getOldFileVerions(fileId) {
      this.loading = true
      const requestParams = {
        method: "GET",
        url: 'files/' + fileId + "/old_versions",
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        this.oldFileVersions = await res.json()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    getFiltered(e) {
      this.filteredFiles = e
    },
    onCloseUploadDialog() {
      this.showFileUploadDialog = false
      this.refresh()
    },
    async refresh() {
      await this.getFiles()
      await this.getObjectPermissions()
      this.$emit('files-refreshed')
    },
    downloadFile(file) {
      this.fileToDownload = file
      this.showDownloadDialog = true
    },
    async onTouConfirmation() {
      let url = process.env.VUE_APP_API_URL
      const request = new Request(
        url + '/v1/files/' + this.fileToDownload.id + '/download?accept_terms_of_use=true',
        {
          method: "GET",
          headers: {
            'x-access-token': this.$store.state.token,
            "Content-Type": "application/json"
          },
          credentials: 'include', // Ensure cookies are always sent
        },

      )
      fetch(request)
        .then((res) => { return res.blob() })
        .then((data) => {
          var a = document.createElement("a")
          a.href = window.URL.createObjectURL(data)
          a.download = this.fileToDownload.original_filename
          a.click()
          this.showDownloadDialog = false
        })
    },
    uploadFile() {
      this.selectedFile = null
      this.selectedRestMethod = 'POST'
      this.showUploadDialog = true
    },
    updateFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = 'PUT'
      this.showUploadDialog = true
    },
    editFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = 'PATCH'
      this.showUploadDialog = true
    },
    deleteFile(file) {
      this.selectedFile = file
      this.selectedRestMethod = ''
      this.showDeleteDialog = true
    },
    async onDeleteConfirmed() {
      const requestParams = {
        method: "DELETE",
        url: 'files/' + this.selectedFile.id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 204) {
          this.showDeleteDialog = false
          await this.refresh()
        }
        else {
          const data = await res.json()
          this.notSavedAlertText = this.extractFirstErrorMessage(data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    showEventDialog(event_id) {
      this.selectedEventId = event_id
      this.showEventOverviewDialog = true
    },
    async loadOldVersion({ item, value }) {
      if (value)
        await this.getOldFileVerions(item.id)
    },
    shortenString(str) {
      if (!str) return ""
      if (str.length <= this.nameStringLegth) {
        return str;
      } else {
        return str.substring(0, this.nameStringLegth) + '...';
      }
    }
  }
}
</script>

<style>
.linebreak {
  word-wrap: break-word;
  display: inline-block;
  max-width: 20ch;
}
.clickable-text {
  cursor: pointer;
  white-space: pre-line;
}
</style>