var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showContextDialog)?_c('ContextDialog',{attrs:{"visible":_vm.showContextDialog,"context-id":_vm.selectedContextId,"context-name":_vm.selectedContextName},on:{"close":function($event){_vm.showContextDialog = false}}}):_vm._e(),(this.$store.state.token || this.$store.state.name)?_c('v-card',{staticClass:"mx-auto mt-12",attrs:{"max-width":"600"}},[_c('v-card-title',[_vm._v("My Contexts")]),_c('v-card-text',{staticClass:"justify-center"},[_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.myContexts,"loading":_vm.loading,"item-key":"id","footer-props":{
          'next-icon':_vm.$icons.mdiChevronRight,
          'last-icon':_vm.$icons.mdiChevronDoubleRight,
          'prev-icon':_vm.$icons.mdiChevronLeft,
          'first-icon':_vm.$icons.mdiChevronDoubleLeft
          },"header-props":{
          'sort-icon': _vm.$icons.mdiChevronUp,
          }},on:{"click:row":_vm.showContext}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }