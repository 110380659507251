import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Legs from '@/views/Legs.vue'
import Platforms from '@/views/Platforms.vue'
import TotalDeliverables from '@/views/TotalDeliverables.vue'
import TotalDataLinks from '@/views/TotalDataLinks.vue'
import TotalFiles from '@/views/TotalFiles.vue'
import TotalEvents from '@/views/TotalEvents.vue'
import Gears from '@/views/Gears.vue'
import Persons from '@/views/Persons.vue'
import Affiliations from '@/views/Affiliations.vue'
import Projects from '@/views/Projects.vue'
import Sponsors from '@/views/Sponsors.vue'
import Simulations from '@/views/Simulations.vue'
import Experiments from '@/views/Experiments.vue'
import Locations from '@/views/Locations.vue'
import EventNetworks from '@/views/EventNetworks.vue'
import Files from '@/components/Files/Files.vue'
import DataLinks from '@/components/DataLinks/DataLinks.vue'
import Contexts from '@/views/Contexts.vue'
import LegDeliverables from '@/components/Deliverables/Deliverables.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import DocuDialog from '@/components/DocuDialog.vue'
import ImprintDialog from '@/components/ImprintDialog.vue'
import PrivacyPolicyDialog from '@/components/PrivacyPolicyDialog.vue'
import MyOsis from '@/views/MyOsis.vue'
import MyContexts from '@/views/MyContexts.vue'
import Token from '@/views/Token.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		children: [{
			name: 'Login',
			path: 'login',
			component: LoginDialog
		}]
	},
	{
		path: '/docu',
		name: 'Docu',
		component: DocuDialog
	},
	{
		path: '/imprint',
		name: 'Imprint',
		component: ImprintDialog
	},
	{
		path: '/privacy_policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicyDialog
	},
	{
		path: '/expeditions/:leg_id?',
		name: 'Legs',
		component: Legs,
		children: [{
			name: 'LegEvents',
			path: 'events/:event_id?',
			children: [{
				name: 'LegEventFiles',
				path: 'files',
			}, {
				name: 'LegEventLinks',
				path: 'links',
			}, {
				name: 'LegEventExternalIds',
				path: 'ids'
			}],
		}, {
			name: 'LegFiles',
			path: 'files',
			component: Files
		}, {
			name: 'LegLinks',
			path: 'links',
			component: DataLinks
		}, {
			name: 'LegDeliverables',
			path: 'deliverables/:deliverable_id?',
			component: LegDeliverables
		}],
		props: true
	},
	{
		path: '/platforms/:platform_id?',
		name: 'Platforms',
		component: Platforms,
		children: [{
			name: 'PlatformExternalIds',
			path: 'ids'
		}],
		props: true
	},
	{
		path: '/simulations/:simulation_id?',
		name: 'Simulations',
		component: Simulations,
		children: [{
			name: 'SimulationFiles',
			path: 'files'
		}, {
			name: 'SimulationLinks',
			path: 'links'
		}],
		props: true
	},
	{
		path: '/experiments/:experiment_id?',
		name: 'Experiments',
		component: Experiments,
		children: [{
			name: 'ExperimentFiles',
			path: 'files'
		}, {
			name: 'ExperimentLinks',
			path: 'links'
		}],
		props: true
	},
	{
		path: '/deliverables',
		name: 'TotalDeliverables',
		component: TotalDeliverables
	},
	{
		path: '/data_links',
		name: 'TotalDataLinks',
		component: TotalDataLinks
	},
	{
		path: '/files',
		name: 'TotalFiles',
		component: TotalFiles
	},
	{
		path: '/events',
		name: 'TotalEvents',
		component: TotalEvents
	},
	{
		path: '/devices/:gear_id?',
		name: 'Gears',
		component: Gears,
		children: [{
			name: 'GearExternalIds',
			path: 'ids'
		}],
		props: true
	},
	{
		path: '/persons/:person_id?',
		name: 'Persons',
		component: Persons
	},
	{
		path: '/affiliations/:affiliation_id?',
		name: 'Affiliations',
		component: Affiliations
	},
	{
		path: '/projects/:project_id?',
		name: 'Projects',
		component: Projects
	},
	{
		path: '/sponsors/:sponsor_id?',
		name: 'Sponsors',
		component: Sponsors
	},
	{
		path: '/locations/:location_id?',
		name: 'Locations',
		component: Locations
	},
	{
		path: '/event_networks/:event_network_id?',
		name: 'EventNetworks',
		component: EventNetworks,
		children: [{
			name: 'EventNetworkFiles',
			path: 'files'
		},
		{
			name: 'EventNetworkEvents',
			path: 'events'
		},
		{
			name: 'EventNetworkLinks',
			path: 'links'
		}],
		props: true
	},
	{
		path: '/contexts/:context_id?',
		name: 'Contexts',
		component: Contexts,
		children: [{
			name: 'ContextExternalIds',
			path: 'ids'
		}],
		props: true
	},
	{
		path: '/my_osis',
		name: 'MyOsis',
		component: MyOsis
	},
	{
		path: '/my_contexts',
		name: 'MyContexts',
		component: MyContexts
	},
	{
		path: '/token',
		name: 'Token',
		component: Token
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
