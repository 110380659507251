<template>
  <div class="mx-14 mt-6">
    <ErrorAlert
      v-if="experimentDoesNotExist"
      @close="experimentDoesNotExist = false"
    >
      {{ experimentDoesNotExistText }}
    </ErrorAlert>
    <h4 class="display-1 mb-4">Experiments</h4>
    <ExperimentFormDialog
      v-if="showExperimentFormDialog"
      :visible="showExperimentFormDialog"
      title="New Experiment"
      @saved="
        showExperimentFormDialog = false;
        onExperimentSave($event);
      "
      @close="
        showExperimentFormDialog = false;
        refresh();
      "
    >
    </ExperimentFormDialog>
    <ExperimentOverviewDialog
      ref="experimentOverviewDialog"
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :experiment-id="selectedExperimentId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </ExperimentOverviewDialog>
    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="labelSearch"
          hint="Search Label"
          label="Label"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-col sm="2">
        <Autocomplete
          v-if="getContextPermission"
          v-model="selectedContextId"
          label="Context"
          url="contexts/short"
          item-text="name"
          item-value="id"
          @input="contextInput"
          :key=autocompleteContextKey
        ></Autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        sm="2"
        v-if="postPermission"
        class="text-right"
      >
        <v-btn
          class="elevation-1 plus-button"
          fab
          small
          @click="addExperiment"
        >
          <v-icon color="white"> {{$icons.mdiPlus}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="experiments"
      :options.sync="options"
      :server-items-length="totalExperiments"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="experimentsItemsPerPage"
      @click:row="showExperimentDialog"
    >
      <template v-slot:[`item.duration`]="{ item }">
        {{ item.date_start }} - {{ item.date_end }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Experiments',
  components: {
    ExperimentFormDialog: () => import('@/components/Experiments/ExperimentFormDialog.vue'),
    ExperimentOverviewDialog: () => import('@/components/Experiments/ExperimentOverviewDialog.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      getContextPermission: false,
      labelSearch: '',
      nameAwaitingSearch: false,
      totalExperiments: 0,
      experiments: [],
      selectedExperimentId: null,
      selectedContextId: null,
      autocompleteContextKey: 0,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Label',
          value: 'label'
        },
        {
          text: 'Subject',
          value: 'subject'
        },
        {
          text: 'Species',
          value: 'species'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Duration of experiment',
          value: 'duration',
          sortable: false,
        },
        {
          text: 'PI',
          value: 'leader',
          sortable: false,
        },
        {
          text: 'Files',
          value: 'file_count',
          sortable: false,
        },
        {
          text: 'Links',
          value: 'data_link_count',
          sortable: false,
        },
      ],
      showExperimentFormDialog: false,
      showOverviewDialog: false,
      experimentDoesNotExist: false,
      experimentDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.experiment_id': {
      handler: function (experiment_id) {
        if (experiment_id) {
          this.selectedExperimentId = parseInt(experiment_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedLegId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    labelSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
      this.getPermissions()
    }
  },
  async mounted() {
    if (this.$route.params.labelSearch) {
      this.labelSearch = this.$route.params.labelSearch
    }
    if (this.$route.params.contextId) {
      this.$router.replace({ query: { contextId: this.$route.params.contextId } })
    }
    if (this.$route.query.contextId) {
      this.selectedContextId = parseInt(this.$route.query.contextId)
      this.autocompleteContextKey += 1
    }
    await this.getPermissions()
    if (this.$route.params.experiment_id) {
      let experimentExists = await this.doesExperimentExist(this.$route.params.experiment_id)
      if (experimentExists) {
        this.selectedExperimentId = this.$route.params.experiment_id
        this.showOverviewDialog = true
      }
      else {
        this.experimentDoesNotExist = true
        this.experimentDoesNotExistText = 'Experiment ' + this.$route.params.experiment_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.labelSearch) { filter.label = this.labelSearch }
      if (this.selectedContextId) { filter.contexts = [{ 'id': this.selectedContextId }] }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    experimentsItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("experimentsItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("experimentsItemsPerPage", value);
      },
    },
  },
  methods: {
    async getPermissions() {
      let permissionsToAsk = {
        'postPermission': {
          route: '/v1/experiments',
          action: 'POST'
        },
        'getContextPermission': {
          route: '/v1/contexts/short',
          action: 'GET'
        }
      }
      let permissions = await this.getRoutePermission(permissionsToAsk)
      this.postPermission = permissions.postPermission.permission
      this.getContextPermission = permissions.getContextPermission.permission
    },
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'Experiments' })
    },
    onExperimentSave(experiment) {
      this.refresh()
      this.showExperimentDialog(experiment)
    },
    showExperimentDialog(experiment) {
      this.$router.push({ name: 'Experiments', params: { experiment_id: experiment.id } })
    },
    addExperiment() {
      this.selectedExperimentId = null
      this.showExperimentFormDialog = true
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getExperiments()
        if (data) {
          this.experiments = data.data
          this.totalExperiments = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getExperiments() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'experiments',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesExperimentExist(id) {
      const requestParams = {
        method: "GET",
        url: 'experiments/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    contextInput() {
      this.$router.replace({ query: { contextId: this.selectedContextId } });
      this.filterInput()
    },
    filterInput() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>

<style>
</style>