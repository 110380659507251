<template>
  <div>
    <v-card
      v-if="this.$store.state.token || this.$store.state.name"
      class="mx-auto mt-12"
      max-width="1200"
    >
      <v-card-title> My Deliverables </v-card-title>
      <v-card-text class="justify-center">
        <MyDeliverables />
      </v-card-text>
    </v-card>
    <v-card
      max-width="1200"
      class="mx-auto mt-4"
      flat
    >
      <v-row>
        <v-col sm="4">
          <Autocomplete
            v-model="selectedContextId"
            label="Community Context"
            url="contexts/short"
            item-text="name"
            item-value="id"
            @input="contextInput"
            :key=autocompleteContextKey
          ></Autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <v-card class="mx-auto osis-card">
            <v-card-text>
              <v-btn
                class="osis-text-button"
                text
                @click="searchLegs"
              >
                <v-icon
                  class="mr-4"
                  color=black
                  size="25"
                >{{$icons.mdiFerry}}</v-icon>
                <div class="text-h5">
                  Expeditions
                </div>
              </v-btn>
              <v-row>
                <v-col sm="10">
                  <v-text-field
                    v-model="legNameSearch"
                    hint="Search Name"
                    label="Name"
                    persistent-hint
                    v-on:keyup.enter="searchLegs"
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-btn
                    icon
                    @click="searchLegs"
                  ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                </v-col>
              </v-row>
              <LatestLegs :selected-context-id=selectedContextId>
              </LatestLegs>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          class=""
          sm="4"
        >
          <v-card class="mx-auto osis-card">
            <v-card-text class="text-h5">
              <v-btn
                class="osis-text-button"
                text
                @click="searchSimulations"
              >
                <v-icon
                  class="mr-4"
                  color=black
                  size="25"
                >{{$icons.mdiEarth}}</v-icon>
                <div class="text-h5">
                  Simulations
                </div>
              </v-btn>
              <v-row>
                <v-col sm="10">
                  <v-text-field
                    v-model="simulationNameSearch"
                    hint="Search Name"
                    label="Name"
                    persistent-hint
                    v-on:keyup.enter="searchSimulations"
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-btn
                    icon
                    @click="searchSimulations"
                  ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                </v-col>
              </v-row>
              <LatestSimulations :selected-context-id=selectedContextId>
              </LatestSimulations>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col sm="4">

          <v-card class="mx-auto osis-card">
            <v-card-text class="text-h5">
              <v-btn
                class="osis-text-button"
                text
                @click="searchExperiments"
              >
                <v-icon
                  class="mr-4"
                  color=black
                  size="25"
                >{{$icons.mdiTestTube}}</v-icon>
                <div class="text-h5">
                  Experiments
                </div>
              </v-btn>
              <v-row>
                <v-col sm="10">
                  <v-text-field
                    v-model="experimentLabelSearch"
                    hint="Search Label"
                    label="Label"
                    persistent-hint
                    v-on:keyup.enter="searchExperiments"
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-btn
                    icon
                    @click="searchExperiments"
                  ><v-icon>{{$icons.mdiMagnify}}</v-icon></v-btn>
                </v-col>
              </v-row>
              <LatestExperiments :selected-context-id=selectedContextId>
              </LatestExperiments>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="this.$store.state.token || this.$store.state.name"
      class="mx-auto mb-12"
      max-width="1200"
    >
      <v-card-title>Latest Files</v-card-title>
      <v-card-text class="justify-center">
        <LatestFiles :selected-context-id=selectedContextId>
        </LatestFiles>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'MyOsis',
  components: {
    MyDeliverables: () => import('@/components/Deliverables/MyDeliverables.vue'),
    LatestLegs: () => import('@/components/Legs/LatestLegs.vue'),
    LatestSimulations: () => import('@/components/Simulations/LatestSimulations.vue'),
    LatestExperiments: () => import('@/components/Experiments/LatestExperiments.vue'),
    LatestFiles: () => import('@/components/Files/LatestFiles.vue'),
    Autocomplete: () => import('@/components/Basics/Autocomplete.vue'),
  },

  data() {
    return {
      legNameSearch: '',
      simulationNameSearch: '',
      experimentLabelSearch: '',
      selectedContextId: null,
      autocompleteContextKey: 0
    }
  },
  watch: {

  },
  mounted() {
    if (this.$route.query.contextId) {
      this.selectedContextId = parseInt(this.$route.query.contextId)
      this.autocompleteContextKey += 1
    }
  },
  methods: {
    searchLegs() {
      this.$router.push({ name: 'Legs', params: { nameSearch: this.legNameSearch, contextId: this.selectedContextId } })
    },
    searchSimulations() {
      this.$router.push({ name: 'Simulations', params: { nameSearch: this.simulationNameSearch, contextId: this.selectedContextId } })
    },
    searchExperiments() {
      this.$router.push({ name: 'Experiments', params: { labelSearch: this.experimentLabelSearch, contextId: this.selectedContextId } })
    },
    contextInput() {
      this.$router.replace({ query: { contextId: this.selectedContextId } });
    }
  }
}
</script>

