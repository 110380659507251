<template>
  <div class="mx-14 mt-6">
    <ErrorAlert
      v-if="eventNetworkDoesNotExist"
      @close="eventNetworkDoesNotExist = false"
    >
      {{ eventNetworkDoesNotExistText }}
    </ErrorAlert>
    <h4 class="display-1 mb-4">Networks</h4>

    <EventNetworkOverviewDialog
      v-if="showOverviewDialog"
      :visible="showOverviewDialog"
      :event-network-id="selectedEventNetworkId"
      @close="
        showOverviewDialog = false;
        refresh();
        redirectHome();
      "
    >
    </EventNetworkOverviewDialog>

    <v-row>
      <v-col sm="2">
        <v-text-field
          v-model="nameSearch"
          hint="Search Name"
          label="Name"
          persistent-hint
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>

    </v-row>

    <v-data-table
      class="table-cursor osis-table elevation-2"
      :headers="headers"
      :items="eventNetworks"
      :options.sync="options"
      :server-items-length="totalEventNetwork"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
        'show-first-last-page': true,
        'next-icon':$icons.mdiChevronRight,
        'last-icon':$icons.mdiChevronDoubleRight,
        'prev-icon':$icons.mdiChevronLeft,
        'first-icon':$icons.mdiChevronDoubleLeft
      }"
      :header-props="{
         'sort-icon': $icons.mdiChevronUp,
      }"
      :items-per-page.sync="eventNetworksItemsPerPage"
      @click:row="showEventNetworkDialog"
    >
      <template v-slot:[`item.files`]="{ item }">
        {{item.file_count+item.event_file_count_sum}}
      </template>
      <template v-slot:[`item.links`]="{ item }">
        {{item.data_link_count+item.event_data_link_count_sum}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import permission from '@/mixins/permissions'
import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'EventNetworks',
  components: {
    EventNetworkOverviewDialog: () => import('@/components/EventNetworks/EventNetworkOverviewDialog.vue'),
    ErrorAlert: () => import('@/components/ErrorAlert.vue'),
  },
  mixins: [permission, apiRequests],
  data() {
    return {
      postPermission: false,
      nameSearch: '',
      nameAwaitingSearch: false,
      totalEventNetwork: 0,
      eventNetworks: [],
      abortController: null,
      selectedEventNetworkId: null,
      loading: true,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'networkname'
        },
        {
          text: 'Responsible Person',
          value: 'responsible_person',
          sortable: false,
        },
        {
          text: 'Duration of Installation',
          value: 'run_duration'
        },
        {
          text: 'Deployed Sensors',
          value: 'deployed_sensor_count'
        },
        {
          text: 'Files',
          value: 'file_count',
          sortable: false,
        },
        {
          text: 'Links',
          value: 'data_link_count',
          sortable: false,
        }
      ],
      showEventNetworkFormDialog: false,
      showOverviewDialog: false,
      eventNetworkDoesNotExist: false,
      eventNetworkDoesNotExistText: ""
    }
  },
  watch: {
    '$route.params.event_network_id': {
      handler: function (event_network_id) {
        if (event_network_id || event_network_id == 0) {
          this.selectedEventNetworkId = parseInt(event_network_id)
          this.showOverviewDialog = true
        }
        else {
          this.selectedEventNetworkId = null
          this.showOverviewDialog = false
        }
      },
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    nameSearch: {
      handler() {
        if (!this.nameAwaitingSearch) {
          setTimeout(() => {
            this.options.page = 1
            this.getDataFromApi()
            this.nameAwaitingSearch = false
          }, 1000); // 1 sec delay
        }
        this.nameAwaitingSearch = true;
      }
    },
    '$store.state.token': function () {
      this.getDataFromApi()
    }
  },
  async mounted() {
    if (this.$route.params.event_network_id) {
      let eventNetworkExists = await this.doesEventNetworkExist(this.$route.params.event_network_id)
      if (eventNetworkExists) {
        this.selectedEventNetworkId = this.$route.params.event_network_id
        this.showOverviewDialog = true
      }
      else {
        this.eventNetworkDoesNotExist = true
        this.eventNetworkDoesNotExistText = 'EventNetwork ' + this.$route.params.event_network_id + ' does not exist'
        this.redirectHome()
      }
    }
  },
  computed: {
    filter_string: function () {
      let filter = {}
      if (this.nameSearch) { filter.networkname = this.nameSearch }
      return JSON.stringify(filter)
    },
    order_string: function () {
      let order = {}
      if (this.options.sortBy && this.options.sortBy[0]) {
        let direction = "asc"
        if (this.options.sortDesc[0] == true) {
          direction = 'desc'
        }
        order = { [this.options.sortBy[0]]: direction }
      }
      return JSON.stringify(order)
    },
    eventNetworksItemsPerPage: {
      get() {
        // Retrieve the value from the cookie, or use a default value
        return parseInt(this.$cookies.get("eventNetworksItemsPerPage")) || 10;
      },
      set(value) {
        // Save the selected value to the cookie
        this.$cookies.set("eventNetworksItemsPerPage", value);
      },
    },
  },
  methods: {
    refresh() {
      this.getDataFromApi()
    },
    redirectHome() {
      this.$router.push({ name: 'EventNetworks' })
    },
    showEventNetworkDialog(eventNetwork) {
      this.$router.push({ name: 'EventNetworks', params: { event_network_id: eventNetwork.network_id } })
    },
    async getDataFromApi() {
      this.loading = true
      try {
        let data = await this.getEventNetworks()
        if (data) {
          this.eventNetworks = data.data
          this.totalEventNetwork = data.meta.total
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    async getEventNetworks() {
      //cancel previuos call
      if (this.abortController) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      const requestParams = {
        method: "GET",
        url: 'event_networks',
        queryParams: {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          filter: this.filter_string,
          order: this.order_string
        }
      }
      try {
        const res = await this.osisApiRequest(requestParams, this.abortController)
        const data = await res.json()
        return data
      } catch (error) {
        // ignore cancelation error
        if (!(error instanceof DOMException)) { console.log(error) }
      }
    },
    async doesEventNetworkExist(id) {
      const requestParams = {
        method: "GET",
        url: 'event_networks/' + id,
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  },
}
</script>

<style>
</style>