import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userName: '',
    userFullName: '',
    userId: null,
    name: ''
  },
  mutations: {
    setToken(state, value) {
      localStorage.setItem('token', value)
      state.token = value
    },
    deleteToken(state) {
      localStorage.setItem('token', '')
      state.token = ''
    },
    setUserName(state, value) {
      localStorage.setItem('userName', value)
      state.userName = value
    },
    deleteUserName(state) {
      localStorage.setItem('userName', '')
      state.userName = ''
    },
    setName(state, value) {
      state.name = value
    },
    deleteName(state) {
      state.name = ''
    },
    setUserFullName(state, value) {
      localStorage.setItem('userFullName', value)
      state.userFullName = value
    },
    deleteUserFullName(state) {
      localStorage.setItem('userFullName', null)
      state.userFullName = null
    },
    setUserId(state, value) {
      localStorage.setItem('userId', value)
      state.userId = value
    },
    deleteUserId(state) {
      localStorage.setItem('userId', null)
      state.userId = null
    },
    initialiseStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
      }
      if (localStorage.getItem('userName')) {
        state.userName = localStorage.getItem('userName')
      }
      if (localStorage.getItem('userFullName')) {
        state.userFullName = localStorage.getItem('userFullName')
      }
      if (localStorage.getItem('userId')) {
        state.userId = localStorage.getItem('userId')
      }
    },
  },
  actions: {
    setToken(context, value) {
      context.commit('setToken', value)
    },
    deleteToken(context) {
      context.commit('deleteToken')
    },
    setUserName(context, value) {
      context.commit('setUserName', value)
    },
    deleteUserName(context) {
      context.commit('deleteUserName')
    },
    setName(context, value) {
      context.commit('setName', value)
    },
    deleteName(context) {
      context.commit('deleteName')
    },
    setUserFullName(context, value) {
      context.commit('setUserFullName', value)
    },
    deleteUserFullName(context) {
      context.commit('deleteUserFullName')
    },
    setUserId(context, value) {
      context.commit('setUserId', value)
    },
    deleteUserId(context) {
      context.commit('deleteUserId')
    }
  },
  modules: {
  }
})
