<template>
  <div>
    <v-card
      v-if="this.$store.state.token || this.$store.state.name"
      class="mx-auto mt-12"
      max-width="600"
    >
      <v-card-title>My API Token (Expiration: {{ validUntil }} UTC)</v-card-title>
      <v-card-text class="justify-center">
        <div class="osis-header">{{ token }}</div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="this.$store.state.token || this.$store.state.name"
      class="mx-auto mt-2"
      max-width="600"
    >
      <v-card-title>URL</v-card-title>
      <v-card-text class="justify-center">
        <a
          :href="apiURL"
          target="_blank"
        >
          <div class="osis-header">{{ apiURL }}</div>
        </a>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'Token',
  mixins: [apiRequests],
  components: {
  },

  data() {
    return {
      token: '',
      validUntil: '',
      loading: false,
      apiURL: process.env.VUE_APP_API_URL + '/apidocs',
    }
  },
  computed: {
  },
  async mounted() {
    await this.getToken()
    console.log('apiURL:', this.apiURL); // Debugging step

  },
  methods: {
    async getToken() {
      this.loading = true
      try {
        const res = await this.osisApiRequest({ method: "GET", url: 'token' })
        if (res.status == 200) {
          this.res = await res.json()
          this.token = this.res.token
          this.validUntil = this.res.valid_until
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
  }
}
</script>

