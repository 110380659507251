export default {
	methods: {
		async osisApiRequest(params, abortController = null) {
			let headers = {}
			if (typeof params['body'] === "object") {
				headers = {
					'x-access-token': this.$store.state.token
				}
			} else {
				headers = {
					'x-access-token': this.$store.state.token,
					"Content-Type": "application/json"
				}
			}

			let fixedParams = {
				frontend_view: 'true',
			}
			let mergedParams = { ...fixedParams, ...params['queryParams'] }
			let queryParams = new URLSearchParams(mergedParams)
			let url = process.env.VUE_APP_API_URL
			const request = new Request(
				url + '/v1/' + params['url'] + '?' + queryParams,
				{
					method: params['method'],
					headers: headers,
					body: params['body'],
					credentials: 'include', // Ensure cookies are always sent
					...(abortController && { signal: abortController.signal }) // Conditionally add signal
				}
			)
			const res = await fetch(request)
			const resClone = res.clone()
			let data = {}
			try {
				data = await resClone.json()
			} catch {
				data.message = ''
			}

			if (res.status == 401 && data.message == 'Token has expired') {
				this.$store.dispatch('deleteToken')
				this.$store.dispatch('deleteUserName')
			}
			return res
		}
	}
}
//