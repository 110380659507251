<template>
  <div>
    <ContextDialog
      v-if="showContextDialog"
      :visible="showContextDialog"
      :context-id="selectedContextId"
      :context-name="selectedContextName"
      @close="
        showContextDialog = false
      "
    >
    </ContextDialog>
    <v-card
      v-if="this.$store.state.token || this.$store.state.name"
      class="mx-auto mt-12"
      max-width="600"
    >
      <v-card-title>My Contexts</v-card-title>
      <v-card-text class="justify-center">
        <v-data-table
          class="table-cursor osis-table elevation-2"
          :headers="headers"
          :items="myContexts"
          :loading="loading"
          item-key="id"
          :footer-props="{
            'next-icon':$icons.mdiChevronRight,
            'last-icon':$icons.mdiChevronDoubleRight,
            'prev-icon':$icons.mdiChevronLeft,
            'first-icon':$icons.mdiChevronDoubleLeft
            }"
          :header-props="{
            'sort-icon': $icons.mdiChevronUp,
            }"
          @click:row="showContext"
        >
        </v-data-table>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

import apiRequests from '@/mixins/apiRequests'

export default {
  name: 'MyContexts',
  mixins: [apiRequests],
  components: {
    ContextDialog: () => import('@/components/Contexts/ContextDialog.vue'),
  },

  data() {
    return {
      myContexts: [],
      options: {},
      loading: false,
      selectedContextId: null,
      showContextDialog: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Members',
          value: 'member_count',
        }
      ]
    }
  },
  computed: {
  },
  async mounted() {
    await this.getMyContexts()
  },
  methods: {
    async getMyContexts() {
      this.loading = true
      try {
        const res = await this.osisApiRequest({ method: "GET", url: 'my_contexts' })
        if (res.status == 200) {
          this.myContexts = await res.json()
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    showContext(context) {
      this.selectedContextId = context.id
      this.selectedContextName = context.name
      this.showContextDialog = true
    },
  }
}
</script>

